import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import moment from "moment";
import "./basicinfo.css";
import axios from "axios";
import { Country, State, City } from 'country-state-city';

const BasicInfo = forwardRef(
  (
    {
      setErrors,
      errorMessage,
      errors,
      success,
      userInfo,
      setUserInfo,
      basic_info,
      isValid,
    },
    ref
  ) => {
    const onChangeValue = (e) => {
      const { name, value } = e.target;

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined, // or null
      }));

      setUserInfo({ ...userInfo, [name]: value });

    };

    const maximumDate = moment().subtract(21, "year").toDate();
    const minimumDate = moment().subtract(90, "year").toDate();

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [dayOptions, setDayOptions] = useState([]);
    const [dob, setDob] = useState(userInfo.dob ? userInfo.dob : maximumDate);
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');


    const getCountries = () => {
      const countries = Country.getAllCountries();
      return countries.filter((country) => {
        return country.isoCode === 'US' || country.isoCode === 'CA';
      });
    };
    
    const handleCountryChange = (e) => {
      const selectedCountry = e.target.value;
      setCountry(selectedCountry);
      errors.country = undefined;
      setState('');
      setCity('');

      const countryDetails = Country.getCountryByCode(selectedCountry);
      console.log("Country details below");
      console.log(countryDetails);

      if (countryDetails) {

        const { latitude, longitude, name } = countryDetails;
    
        // Update userInfo with selected country and its coordinates
        setUserInfo({
          ...userInfo,
          country: selectedCountry,
          countryName: name,
          state: '',
          stateName: '',
          city: '',
          cityName: '',
          latitude,
          longitude,
        });
      } else {
        // In case country coordinates are not found, keep them undefined
        setUserInfo({
          ...userInfo,
          country: selectedCountry,
          countryName: "Not provided",
          state: '',
          stateName: '',
          city: '',
          cityName: '',
          latitude: '',
          longitude: '',
        });
      }
    };
    
    const handleStateChange = (e) => {
      const selectedState = e.target.value;
      setState(selectedState);
      errors.state = null;
      setCity('');
    
      // Get the state object to fetch the coordinates
      // const stateObj = State.getStatesOfCountry(country).find(
      //   (s) => s.name === selectedState
      // );

      const stateDetails = State.getStateByCodeAndCountry(selectedState, country)
      console.log(stateDetails);

      if (stateDetails) {
        const { latitude, longitude, name } = stateDetails;
    
        // Update userInfo with selected state and its coordinates
        setUserInfo({
          ...userInfo,
          state: selectedState,
          stateName: name,
          city: '',
          cityName: '',
          latitude,
          longitude,
        });
      } else {
        // In case state coordinates are not found, keep them undefined
        setUserInfo({
          ...userInfo,
          state: selectedState,
          stateName: "Not provided",
          cityName: '',
          latitude,
          longitude,
        });
      }
    };
    
    const handleCityChange = (e) => {
      const selectedCity = e.target.value;
      setCity(selectedCity);
      errors.city = null;

      // Get the city object to fetch the coordinates
      const cityObj = City.getCitiesOfState(country, state).find(
        (c) => c.name === selectedCity
      );

      console.log("Checking city object");
      console.log(cityObj);

      if (cityObj) {
        const { latitude, longitude, name } = cityObj;
        setUserInfo({
          ...userInfo,
          city: selectedCity,
          latitude,
          longitude,
        });
      }
    };  

    const changeNoOfDaysInMonth = () => {
      const daysInMonth = new Date(year, month, 0).getDate();
      // Generate an array of days based on the number of days in the selected month
      const days = Array.from(
        { length: daysInMonth },
        (_, index) => index + 1
      );

      setDayOptions(days);
    }

    useEffect(() => {
      changeNoOfDaysInMonth()
    }, [month])

    let minOffset = 22,
      maxOffset = 100;
    let thisYear = new Date().getFullYear();
    let allYears = [];
    
    for (let x = minOffset; x <= maxOffset; x++) {
      allYears.push(thisYear - x);
    }

    const yearList = allYears.map((x) => {
      return (
        <option key={x} value={x}>
          {x}
        </option>
      );
    });

    const handleYearChange = (e) => {
      const selectedYear = e.target.value;
      userInfo["year"] = selectedYear;
      setYear(selectedYear);
    };

    const handleChangeMonth = (e) => {
      const selectedMonth = e.target.value;
      userInfo["month"] = selectedMonth;
      setMonth(selectedMonth);
      // Check other fields and clear error
      if (year != "" && day != "" ) {
        console.log('change month spotted');
        errors.dob = null;
      }
    };

    const handleChangeDay = (event) => {
      userInfo["day"] = event.target.value;
      setDay(parseInt(event.target.value));

      // Check other fields and clear error
      if (year != "" && month != "" ) {
        console.log('change day spotted');
        errors.dob = null;
      }
    };

    const date_of_birth = year + "-" + month + "-" + day;
    const date_birth = moment(date_of_birth, "YYYY-MM-DD").format("YYYY-MM-DD");
    userInfo["dob"] = date_birth;
    userInfo["year"] = year;

    useEffect(() => {
      setDob(date_birth);
    }, [year, month, day]);

    useImperativeHandle(ref, () => ({
      submit() {
        if (Object.keys(userInfo).length === 0) {
          const errors = {};
          errors["mobile"] = "Mobile is required";
          errors["email"] = "Email is required";
          errors["password"] = "Password is required";
          errors["firstName"] = "First Name is required";
          errors["lastName"] = "Last Name is required";
          errors["nickName"] = "Nick Name is required";
          errors["dob"] = "Date of Birth is required";
          errors["country"] = "Country is required";
          errors["state"] = "State is required";
          errors["city"] = "City is required"
          setErrors(errors);
          return null;
        } else {
          let retvalue = stepOneFormValidation();
          if (retvalue === true) {
            console.log(userInfo);
            console.log('form success');
            
            success();
          } else {
            console.log('Not fully validated');
            return null;
          }
        }
      },
    }));

  const stepOneFormValidation = () => {
    const errors = {};

    // Validate First Name
    if (!userInfo.firstName) {
      errors["firstName"] = "First Name is required";
    } else if (userInfo.firstName.trim().length < 3) {
      errors["firstName"] = "First Name is too short";
    } else if (!userInfo.firstName.match(/^[^0-9][^@#]+$/)) {
      errors["firstName"] = "Name takes only letters";
    }

    // Validate Last Name
    if (!userInfo.lastName) {
      errors["lastName"] = "Last Name is required";
    } else if (userInfo.lastName.trim().length < 3) {
      errors["lastName"] = "Last Name is too short";
    } else if (!userInfo.lastName.match(/^[^0-9][^@#]+$/)) {
      errors["lastName"] = "Last Name is invalid";
    }

    // Validate Nick Name
    if (!userInfo.nickName) {
      errors["nickName"] = "Nick Name is required";
    } else if (userInfo.nickName.trim().length < 3) {
      errors["nickName"] = "Nick Name is too short";
    } else if (!userInfo.nickName.match(/^[^0-9][^@#]+$/)) {
      errors["nickName"] = "Nick Name is invalid";
    }

    // Validate Date of Birth
    const dob = moment(userInfo.dob, "YYYY-MM-DD");
    const today = moment();
    const age = today.diff(dob, "years");

    if (!userInfo.dob) {
      errors["dob"] = "Date of Birth is required";
    } else if (!dob.isValid()) {
      errors["dob"] = "Invalid Date of Birth";
    } else if (dob.isAfter(today)) {
      errors["dob"] = "Date of Birth cannot be in the future";
    } else if (age < 21) {
      errors["dob"] = "You must be at least 21 years old";
    } else if (age > 90) {
      errors["dob"] = "Age cannot exceed 90 years";
    }

    // Validate Email
    if (!userInfo.email) {
      errors["email"] = "Email is required";
    } else if (
      !userInfo.email.match(/[a-zA-Z][a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
    ) {
      errors["email"] = "Email is invalid";
    }

    // Validate Password
    if (!userInfo.password) {
      errors["password"] = "Password is required";
    } else if (userInfo.password.trim().length < 6) {
      errors["password"] = "Password is too short";
    }

    // Validate Mobile Number
    if (!userInfo.mobile) {
      errors["mobile"] = "Mobile is required";
    } else if (userInfo.mobile.length < 10 || userInfo.mobile.length > 12) {
      errors["mobile"] = "Mobile should have min 10 digits & max 12 digits.";
    }

    // Validate Country
    console.log(country);
    if (country == "") {
      console.log('tyring to et country error');
      errors["country"] = "Country is required";
    }

    // Validate State
    if (State.getStatesOfCountry(country).length > 0 && state == "") {
      errors["state"] = "State is required";
    }

    // Validate City
    if (City.getCitiesOfState(country, state).length > 0 && city == "") {
      errors["city"] = "City is required";
    }

    console.log('the errors');
    console.log(errors);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

    return (
      <div className={basic_info}>
        <Row Row className="mb-2">
          <Col>
            <Form.Group controlId="formGridname">
              <Form.Control
                name="firstName"
                isInvalid={errors.firstName !== undefined}
                onFocus={onChangeValue}
                onChange={onChangeValue}
                type="text"
                value={userInfo.firstName}
                className="borderinput"
              />
              <Form.Label className="text-bold">FIRST NAME</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGridname1">
              <Form.Control
                onChange={onChangeValue}
                name="lastName"
                type="text"
                value={userInfo.lastName}
                isInvalid={errors.lastName !== undefined}
                className="borderinput"
                // placeholder="Enter last name"
              />
              <Form.Label className="text-bold">LAST NAME</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row Row className="mb-2">
          <Col className="col-12">
            <Form.Group controlId="formGridNickname">
              <Form.Control
                isInvalid={errors.nickName !== undefined}
                onChange={onChangeValue}
                name="nickName"
                value={userInfo.nickName}
                className="borderinput"
              />
              <Form.Label className="text-bold mb-0">PREFERRED NAME</Form.Label>
              <small className="text-d text-muted">to be use on name tag</small>
              <Form.Control.Feedback type="invalid">
                {errors.nickName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="col-12">
            <Form.Group controlId="duedate">
              <div className="row customDataPicker">
                <div className="col-md-4 col-4">
                  <div className="date-picker dataPicker borderinput">
                    <select className="fixdate" name="year" onChange={handleYearChange}>
                      <option>Year</option>
                      {yearList}
                    </select>
                  </div>
                </div>

                <div className="col-md-4 col-4">
                  <div className="date-picker dataPicker borderinput">
                    <select className="fixdate" name="month" onChange={handleChangeMonth}>
                      <option>Month</option>
                      <option value="1">January</option>
                      <option value="2">Febuary</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 col-4">
                  <div className="date-picker dataPicker borderinput">
                    <select className="fixdate" value={day} onChange={handleChangeDay}>
                      {dayOptions?.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <Form.Label className="text-bold">DOB</Form.Label>
              <input
                type="text"
                className="borderinput form-control is-invalid"
                hidden
              />
              <Form.Control.Feedback type="invalid">
                {errors.dob}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>


        <Form.Group className="mb-2" controlId="formGridEmail">
          <Form.Control
            onChange={onChangeValue}
            type="email"
            isInvalid={errors.email !== undefined}
            value={userInfo.email}
            name="email"
            className="borderinput"
          />
          <Form.Label className="text-bold">EMAIL</Form.Label>
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
          {errorMessage && <p className="error">{errorMessage} </p>}
        </Form.Group>


        <Form.Group className="mb-2" controlId="formGridPassword">
          <Form.Control
            onChange={onChangeValue}
            type="password"
            isInvalid={errors.password !== undefined}
            name="password"
            value={userInfo.password}
            className="borderinput"
          />
          <Form.Label className="text-bold">PASSWORD</Form.Label>
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-2" controlId="formGridMobile">
          <Form.Control
            onChange={onChangeValue}
            type="number"
            value={userInfo.mobile}
            isInvalid={errors.mobile !== undefined}
            name="mobile"
            className="borderinput"
          />
          <Form.Label className="text-bold">PHONE</Form.Label>
          <small className="text-d text-muted">
            {/* Please do not use country code or any special character. */}
            Please do not use + or - or any special character. Only numbers
            allowed e.g., 1432198876
          </small>
          <Form.Control.Feedback type="invalid">
            {errors.mobile}
          </Form.Control.Feedback>
          {errorMessage && <p className="error"> {errorMessage} </p>}
        </Form.Group>
        
        {/* Former country */}
        <Form.Group className="my-4">
          <select value={country} onChange={handleCountryChange}>
            <option value="">Select Country</option>
            {getCountries().map((c) => (
              <option key={c.isoCode} value={c.isoCode}>
                {c.name}
              </option>
            ))}
          </select>
          <Form.Label className="text-bold">Country</Form.Label>
          <input
                type="text"
                className="borderinput form-control is-invalid"
                hidden
              />
          <Form.Control.Feedback type="invalid">
          {errors.country}
          </Form.Control.Feedback>
        </Form.Group>
       

        {/* State */}
        <Form.Group className="my-4">
          <select
            value={state}
            onChange={handleStateChange}
            disabled={!country}
          >
            <option value="">Select State</option>
            {State.getStatesOfCountry(country).map((s) => (
              <option key={s.isoCode} value={s.isoCode}>
                {s.name}
              </option>
            ))}
          </select>
          <Form.Label className="text-bold"> State </Form.Label>
            <input
              type="text"
              className="borderinput form-control is-invalid"
              hidden
            />
          <Form.Control.Feedback type="invalid">
            {errors.state}
          </Form.Control.Feedback>
        </Form.Group>

        {/* City */}
        <Form.Group className="my-4">
          <select
            value={city}
            onChange={handleCityChange}
            disabled={!state}
          >
            <option value="">Select City</option>
            {City.getCitiesOfState(country, state).map((ci) => (
              <option key={ci.name} value={ci.name}>
                {ci.name}
              </option>
            ))}
          </select>
          <Form.Label className="text-bold" >City</Form.Label>
          <input
              type="text"
              className="borderinput form-control is-invalid"
              hidden
            />
          <Form.Control.Feedback type="invalid">
                {errors.city}
          </Form.Control.Feedback>
        </Form.Group>


      </div>
    );
  }
);

export default BasicInfo;
