// const ActivateAccount = () => {
//     return (
//         <div>
//             Done refreshe
//         </div>
//     )
// }

// export default ActivateAccount;

import React, { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useNavigate, Link } from "react-router-dom";
import { generateBarcode } from "../../api/auth";
import { removeToken } from "../../cookiesHelper";
import Logo from "../../LAID_logo_512.png";
import Android from "./android.png";
import Ios from "./ios.png";
import "./admin.css";

const ActivateAccount = () => {
  const navigate = useNavigate();
  const [barcode, setBarcode] = useState();

  useEffect(() => {
    generateBarcode()
      .then((data) => {
        setBarcode(data.token);
        //setToken(data.token);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      console.log("In here");
      removeToken();
      navigate("/done");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const onLogout = () => {
    removeToken();
    navigate("/");
  };

  return (
    <div className="login_wrapper">
      <div id="log" className="login_logo">
        <img src={Logo} alt="pic" />
      </div>
      <div id="qr_class" className="component_wrapper">
        <div className="row mb-5 mb-md-0">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <h3
                  className=" pb-1 mb-3"
                  style={{ display: "inline-block", fontWeight: "500" }}
                >
                  Your Dating Profile is Complete!<br></br>
                  Now, download the mobile app.
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <span style={{ display: "inline-block" }}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.laidapp.speeddate"
                    className="nav-link px-4"
                  >
                    <img src={Android} alt={Android} />
                  </a>
                </span>
                <span style={{ display: "inline-block" }}>
                  <a
                    href="https://apps.apple.com/in/app/lesbian-adventures-in-dating/id6443671445"
                    className="nav-link px-5"
                  >
                    <img src={Ios} alt={Ios} />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="text-center">
          <h5
            className="border-bottom pb-1"
            style={{ display: "inline-block", fontWeight: "500" }}
          >
            Download the app to view the dates
          </h5>
          <br />
          <span style={{ display: "inline-block" }}>
            <a href="#" className="nav-link px-4">
              <img src={Android} alt={Android} />
            </a>
          </span>
          <span style={{ display: "inline-block" }}>
            <a href="#" className="nav-link px-5">
              <img src={Ios} alt={Ios} />
            </a>
          </span>
        </div> */}
        {/* {barcode && (
          <div>
            <QRCode
              value={barcode}
              logoWidth={50}
              logoHeight={50}
              eyeRadius={5}
            />
            <div> {`Barcode: ${barcode}`}</div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ActivateAccount;
