import React, { useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import EmailStep from "./EmailStep";
import OTPStep from "./OTPStep";
import ChangePasswordStep from "./ChangePasswordStep";
import {
  FORGOT_CHANGE_PASSWORD_STEP,
  FORGOT_EMAIL_STEP,
  FORGOT_OTP_STEP,
  LOGIN_STEP,
} from "../../../constants";

const ForgotPassword = ({ changeStep }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [errors, setErrors] = useState({});
  const [steps, setSteps] = useState(FORGOT_EMAIL_STEP);

  const [otp, setOtp] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const ref = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    ref.current.submit();
  };

  const renderSteps = () => {
    switch (steps) {
      case FORGOT_EMAIL_STEP:
        return (
          <EmailStep
            ref={ref}
            setErrors={handleError}
            errors={errors}
            successMessage={setSuccessMessage}
            loading={setLoading}
            success={emailStepSuccess}
            email_set={"email_set"}
          />
        );
      case FORGOT_OTP_STEP:
        return (
          <OTPStep
            ref={ref}
            loading={setLoading}
            setErrors={setErrors}
            otp={otp}
            showError={setModalShow}
            errors={errors}
            success={nextStep}
          />
        );
      case FORGOT_CHANGE_PASSWORD_STEP:
        return (
          <ChangePasswordStep
            ref={ref}
            setErrors={handleError}
            errors={errors}
            successMessage={setSuccessMessage}
            loading={setLoading}
            success={showModal}
          />
        );
      default:
        return <div />;
    }
  };

  const nextStep = (e) => {
    if (e) e.preventDefault();
    //If it is the last step, then go to login
    if (steps === FORGOT_CHANGE_PASSWORD_STEP) {
      goToLogin();
    } else {
      setSteps(steps + 1);
    }
  };

  const onBack = (e) => {
    e.preventDefault();
    //If it is the first step, then go to login
    if (steps === FORGOT_EMAIL_STEP) {
      goToLogin();
    } else {
      setSteps(steps - 1);
    }
  };

  const goToLogin = () => {
    changeStep(LOGIN_STEP);
  };

  const emailStepSuccess = (otp) => {
    setOtp(otp);
    showModal();
  };

  const handleError = (err) => {
    setErrors(err);
    if (Object.keys(err).length === 0 || !err.msg) {
      return;
    }
    setModalShow(true);
  };

  const showModal = () => setModalShow(true);

  const handleModalClose = () => {
    setModalShow(false);
    if (successMessage.length !== 0) {
      setSuccessMessage("");
      nextStep();
    }
  };

  return (
    <>
      <Form>
        {renderSteps()}
        <div style={{ marginTop: "20px" }} />
        {/* <div className="btn_divF"> */}
        <Row>
          <Col>
            <button onClick={onBack} className="mybtn">
              BACK
            </button>
          </Col>
          <Col className="left-right">
            <button
              className="mybtn"
              onClick={handleSubmit}
              style={loading ? { backgroundColor: "#D3D3D3" } : {}}
            >
              <span className="login_text">
                {steps === FORGOT_CHANGE_PASSWORD_STEP ? "FINISH" : "NEXT"}
                {loading && (
                  <div
                    class="spinner-border mb-0"
                    role="status"
                    style={{
                      color: "#fb9568",
                      border: "2px solid",
                    }}
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                )}
              </span>
            </button>
          </Col>
        </Row>

        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {successMessage.length !== 0 ? "Success" : "Error"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {successMessage.length !== 0 ? successMessage : errors.msg}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              CLOSE
            </Button>
          </Modal.Footer>
        </Modal>
        {/* </div> */}
      </Form>
    </>
  );
};

export default ForgotPassword;
