import { getRequest, postRequest, putRequest } from ".";

export const loginRequest = async (data) =>
    new Promise((resolve, reject) => {
        postRequest("/auth/login", data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const registerRequest = async (data) =>
    new Promise((resolve, reject) => {
        postRequest("/auth/signup", data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const forgotPasswordRequest = async (data) =>
    new Promise((resolve, reject) => {
        postRequest("/auth/forgotPassword", data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const changePasswordRequest = async (data) =>
    new Promise((resolve, reject) => {
        putRequest("/auth/password", data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const applyToYouRequest = async () =>
    new Promise((resolve, reject) => {
        getRequest("/auth/applyToYou")
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const wishToAttractRequest = async () =>
    new Promise((resolve, reject) => {
        getRequest("/auth/wishToAttract")
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const iAmInsRequest = async () =>
    new Promise((resolve, reject) => {
        getRequest("/auth/iamins")
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const agePrefRequest = async () =>
    new Promise((resolve, reject) => {
        getRequest("/auth/ageprefs")
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const confirmRegisterRequest = async (data) =>
    new Promise((resolve, reject) => {
        putRequest("/auth/confirmRegister", data)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const generateBarcode = async () =>
    new Promise((resolve, reject) => {
        getRequest("/auth/generateToken")
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });

export const checkEmailPhoneExist = async () =>
    new Promise((resolve, reject) => {
        postRequest("/auth/checkEmailPhoneExist")
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
