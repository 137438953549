import axios from "axios";
import env from "react-dotenv";
import { getToken } from "../cookiesHelper";

const url = env.BASE_URL + "/v1";

const getHeader = () => {
  const token = getToken();
  let header = undefined;
  if (token) {
    header = {
      headers: { Authorization: `Bearer ${token}` },
    };
  }
  return header;
};

export const postRequest = async (endpoint, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(url + endpoint, data, getHeader())
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response.data.message));
  });

export const getRequest = async (endpoint) =>
  new Promise((resolve, reject) => {
    axios
      .get(url + endpoint, getHeader())
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response.data.message));
  });

export const putRequest = async (endpoint, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(url + endpoint, data, getHeader())
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response.data.message));
  });

export const deleteRequest = async (endpoint) =>
  new Promise((resolve, reject) => {
    axios
      .delete(url + endpoint, getHeader())
      .then((res) => resolve(res.data))
      .catch((error) => reject(error.response.data.message));
  });
