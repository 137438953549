import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { loginRequest } from "../../../api/auth";
import {
    FORGOT_PASSWORD_STEP,
    REGISTER_STEP,
    VERIFY_EMAIL_STEP,
} from "../../../constants";
import { getToken, setAdmin, setToken } from "../../../cookiesHelper";
import { useNavigate } from "react-router-dom";
import "./login.css";

const Login = ({ changeStep, login_css }) => {
    const navigate = useNavigate();

    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");

    let token = getToken();

    useEffect(() => {
        if (token) {
            console.log('fghgc')
            navigate("/home");
        }
    }, [token]);

    const handleModalClose = () => {
        setModalShow(false);
        if (successMessage.length !== 0) {
            setSuccessMessage("");
            changeStep(VERIFY_EMAIL_STEP);
        }
    };

    const handleSuccessModalShow = (msg) => {
        setSuccessMessage(msg);
        setModalShow(true);
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errors, setErrors] = useState({});

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!isValid()) return;
        setLoading(true);
        loginRequest({ email, password })
            .then((res) => {
                setLoading(false);
                setToken(res.token);
                setAdmin(false);
                if (!res.data.email_verified) {
                    handleSuccessModalShow(`OTP sent to ${email}`);
                } else {
                    if (email === window.env.ADMIN) {
                        setAdmin(true);
                        navigate("/admin");
                    } else {
                        navigate("/home");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                errors["msg"] = err;
                setErrors(errors);
                setModalShow(true);
            });
    };

    const isValid = () => {
        const errors = {};
        if (email.trim() === "") errors["email"] = "Email is required";
        else if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i))
            errors["email"] = "Email is invalid";
        else if (password.trim() === "")
            errors["password"] = "Password is required";
        else if (password.trim().length < 6)
            errors["password"] = "Password must be at least 6 characters long";
        else if (password.trim() === "")
            errors["password"] = "Password is required";
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const goToForgotPassword = () => {
        changeStep(FORGOT_PASSWORD_STEP);
    };

    const goToRegisterScreen = () => {
        changeStep(REGISTER_STEP);
    };

    return (
        <div className={login_css}>
            <Form className="login_form_front">
                <Form.Group
                    className="mb-0 width-100"
                    controlId="formBasicEmail"
                >
                    <i class="fa fa-envelope cercle-2"></i>
                    <Form.Control
                        className="Lb_login_form"
                        isInvalid={errors.email !== undefined}
                        onChange={onEmailChange}
                        type="email"
                        placeholder="Email"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                    className="mb-5 width-100"
                    controlId="formBasicPassword"
                >
                    <i class="fa fa-lock cercle-2" aria-hidden="true"></i>
                    <Form.Control
                        className="Lb_login_form"
                        isInvalid={errors.password !== undefined}
                        onChange={onPasswordChange}
                        type="password"
                        placeholder="Password"
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.password}
                    </Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" />
                </Form.Group> */}
                <div className="btn_div width-100">
                    <button
                        onClick={onSubmit}
                        style={loading ? { backgroundColor: "#D3D3D3" } : {}}
                    >
                        <span className="login_text">
                            login
                            {loading && (
                                    <div
                                        class="spinner-border mb-0"
                                        role="status"
                                        style={{ color: "#fb9568", border:"2px solid" }}
                                    >
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                )}
                        </span>
                    </button>
                    {/* TODO: More title message */}
                    <Modal show={modalShow} onHide={() => setModalShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {successMessage.length !== 0
                                    ? "Email Verification"
                                    : "Error"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {successMessage.length !== 0
                                ? successMessage
                                : errors.msg}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleModalClose}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="mt-4  text-center">
                    <Button
                        className="lb_rg_btn"
                        type="submit"
                        onClick={goToRegisterScreen}
                    >
                        Register Here
                    </Button>
                </div>
                <div className=" mt-5 text-center">
                    <element id="forgot_pass">
                        <a onClick={goToForgotPassword}>
                            Forgot Password? Click Here
                        </a>
                    </element>
                </div>
            </Form>
        </div>
    );
};

export default Login;
