import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import moment from "moment";
import "./basicinfo.css";
import axios from "axios";

const BasicInfo = forwardRef(
    (
        {
            setErrors,
            errorMessage,
            errors,
            success,
            userInfo,
            setUserInfo,
            basic_info,
        },
        ref
    ) => {
        const onChangeValue = (e) => {
            const { name, value } = e.target;
            setUserInfo({ ...userInfo, [name]: value });
        };

        const maximumDate = moment().subtract(21, "year").toDate();
        const minimumDate = moment().subtract(90, "year").toDate();

        const onDateChange = (value) => {
            setDob(value);
            userInfo["dob"] = moment(value).format("YYYY-MM-DD");
        };

        const [dob, setDob] = useState(
            userInfo.dob ? userInfo.dob : maximumDate
        );

        useImperativeHandle(ref, () => ({
            submit() {
                if (isValid()) return null;
                success();
            },
        }));
        // setSteps(steps + 0) errors["firstName"] = "First Name is required";
        const isValid = () => {
            const errors = {};
            if (!userInfo.firstName) {
                errors["msg"] = "First Name is required";
                setSteps(steps + 0);
            } else if (userInfo.firstName.trim().length < 3) {
                errors["firstName"] = "First Name is too short";
            } else if (!userInfo.firstName.match(/^[^0-9][^@#]+$/)) {
                errors["firstName"] = "Name takes only letters";
            } else if (!userInfo.lastName) {
                errors["lastName"] = "Last Name is required";
                setSteps(steps + 0);
            } else if (userInfo.lastName.trim().length < 3) {
                errors["lastName"] = "Last Name is too short";
            } else if (!userInfo.lastName.match(/^[^0-9][^@#]+$/)) {
                errors["lastName"] = "Last Name is invalid";
            } else if (!userInfo.nickName) {
                errors["nickName"] = "Nick Name is required";
                setSteps(steps + 0);
            } else if (userInfo.nickName.trim().length < 3) {
                errors["nickName"] = "Nick Name is too short";
            } else if (!userInfo.nickName.match(/^[^0-9][^@#]+$/)) {
                errors["nickName"] = "Nick Name is invalid";
            } else if (!userInfo.dob) {
                errors["dob"] = "Date of Birth is required";
            } else if (userInfo.dob > 21) {
                errors["dob"] = "Age must be 21";
            } else if (!userInfo.email) {
                errors["email"] = "Email is required";
                setSteps(steps + 0);
            } else if (userInfo.email.length === 0) {
                errors["email"] = "Please enter email";
            } else if (!userInfo.email.match(/^[^0-9][^@]+@[^@]+\.[^@]+$/)) {
                errors["email"] = "Email is invalid";
            } else if (!userInfo.password) {
                errors["password"] = "Password is required";
                setSteps(steps + 0);
            } else if (userInfo.password.trim().length < 6) {
                errors["password"] = "Password is too short";
            } else if (!userInfo.mobile) {
                errors["mobile"] = "Mobile is required";
                setSteps(steps + 0);
            } else if (userInfo.mobile.length !== 11 || userInfo.mobile.length !== 10) {
                errors["mobile"] = "Mobile should have 10 digits";
            } else if (userInfo.mobile.length === 0) {
                errors["mobile"] = "Please enter mobile number";
            }
            // else if (!userInfo.mobile.match(/^[0-9]+$/)) {
            //     errors["mobile"] = "Mobile is invalid";
            // } 
            setErrors(errors);
            return Object.keys(errors).length === 0;
        };

        return (
            <div className={basic_info}>
                <Row Row className="mb-2">
                    <Col>
                        <Form.Group controlId="formGridname">
                            <Form.Control
                                name="firstName"
                                isInvalid={errors.firstName !== undefined}
                                onFocus={onChangeValue}
                                onChange={onChangeValue}
                                type="text"
                                value={userInfo.firstName}
                                className="borderinput"
                                // placeholder="Enter first name"
                            />
                            <Form.Label className="text-bold">
                                FIRST NAME
                            </Form.Label>
                            <Form.Control.Feedback type="isValid">
                                {errors.firstName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formGridname1">
                            <Form.Control
                                onChange={onChangeValue}
                                name="lastName"
                                type="text"
                                value={userInfo.lastName}
                                isInvalid={errors.lastName !== undefined}
                                className="borderinput"
                                // placeholder="Enter last name"
                            />
                            <Form.Label className="text-bold">
                                LAST NAME
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                                {errors.lastName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row Row className="mb-2">
                    <Col>
                        <Form.Group controlId="formGridNickname">
                            <Form.Control
                                isInvalid={errors.nickName !== undefined}
                                onChange={onChangeValue}
                                name="nickName"
                                value={userInfo.nickName}
                                className="borderinput"
                            />
                            <Form.Label className="text-bold mb-0">
                                PREFERRED NAME
                            </Form.Label>
                            <small className="text-d text-muted">
                                to be use on name tag
                            </small>
                            <Form.Control.Feedback type="invalid">
                                {errors.nickName}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="duedate">
                            <div className="date-picker borderinput">
                                <DatePicker
                                    maxDate={maximumDate}
                                    minDate={minimumDate}
                                    format={"yyyy-MM-dd"}
                                    onChange={onDateChange}
                                    value={dob}
                                />
                            </div>
                            <Form.Label className="text-bold">DOB</Form.Label>
                            <Form.Control.Feedback type="invalid">
                                {errors.dob}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-2" controlId="formGridEmail">
                    <Form.Control
                        onChange={onChangeValue}
                        type="email"
                        isInvalid={errors.email !== undefined}
                        value={userInfo.email}
                        name="email"
                        className="borderinput"
                    />
                    <Form.Label className="text-bold">EMAIL</Form.Label>
                    <Form.Control.Feedback type="invalid">
                        {errors.email}
                    </Form.Control.Feedback>
                    {errorMessage && <p className="error">{errorMessage} </p>}
                </Form.Group>
                <Form.Group className="mb-2" controlId="formGridPassword">
                    <Form.Control
                        onChange={onChangeValue}
                        type="password"
                        isInvalid={errors.password !== undefined}
                        name="password"
                        value={userInfo.password}
                        className="borderinput"
                    />
                    <Form.Label className="text-bold">PASSWORD</Form.Label>
                    <Form.Control.Feedback type="invalid">
                        {errors.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-2" controlId="formGridMobile">
                    <Form.Control
                        onChange={onChangeValue}
                        type="number"
                        value={userInfo.mobile}
                        isInvalid={errors.mobile !== undefined}
                        name="mobile"
                        className="borderinput"
                    />
                    <Form.Label className="text-bold">PHONE</Form.Label>
                    <Form.Control.Feedback type="invalid">
                        {errors.mobile}
                    </Form.Control.Feedback>
                    {errorMessage && <p className="error"> {errorMessage} </p>}
                </Form.Group>
            </div>
        );
    }
);

export default BasicInfo;
