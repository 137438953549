import React, { useState, forwardRef, useImperativeHandle } from "react";
import Form from "react-bootstrap/Form";
import OTPInput from "otp-input-react";
import "./OTP.css";

const OTPStep = forwardRef(
  ({ setErrors, success, otp, showError, setOtp }, ref) => {
    const [OTP, setOTP] = useState("");
    useImperativeHandle(ref, () => ({
      submit() {
        if (!isValid()) return showError(true);
        if (!otp) setOtp(OTP);
        success();
      },
    }));

    const isValid = () => {
      const errors = {};
      if (OTP.trim() === "") errors["msg"] = "OTP is required";
      else if (otp) if (OTP.trim() !== otp) errors["msg"] = "OTP is invalid";
      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    return (
      <>
        <Form.Group id="form-label" className="mb-3" controlId="formBasicEmail">
          <Form.Label id="form-header">ENTER OTP</Form.Label>
          <br />
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            className="OTP-INPUT"
          />
        </Form.Group>
        <br />
      </>
    );
  }
);

export default OTPStep;
