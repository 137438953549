import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  LOGIN_STEP,
  REGISTER_STEP,
  FORGOT_PASSWORD_STEP,
  VERIFY_EMAIL_STEP,
} from "../../constants";
import { getAdmin } from "../../cookiesHelper";
import Logo from "../../LAID_logo_512.png";
import EmailVerification from "./emailVerification";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import Register from "./register";

const Auth = () => {
  const [authStep, setAuthStep] = useState(LOGIN_STEP);
  const navigate = useNavigate();
  useEffect(() => {
    if (getAdmin()) {
      navigate("/admin");
    }else{
      console.log('insideeee')
        // navigate("/home");
    }
  });

  const renderSteps = () => {
    switch (authStep) {
      case LOGIN_STEP:
        return <Login login_css="login_css" changeStep={setAuthStep} />;
      case REGISTER_STEP:
        return <Register changeStep={setAuthStep} />;
      case FORGOT_PASSWORD_STEP:
        return <ForgotPassword changeStep={setAuthStep} />;
      case VERIFY_EMAIL_STEP:
        return <EmailVerification changeStep={setAuthStep} />;
      default:
        return <Login />;
    }
  };

  return (
    <div className="login_wrapper">
      <div className="login_logo">
        <img src={Logo} alt="pic" />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <h6 className="mb-0">
                  <span style={{ fontWeight: "800" }}>
                    LESBIAN DATING QUEER, BISEXUAL
                  </span>
                </h6>
                <span className="text-muted">(Non-Binary Inclusive)</span>
                <p className="text-justify mt-4">
                  <span className="text-muted">
                    At Lesbian Adventures in Dating (LAID), we are committed to
                    taking the stress and struggle out of lesbian, bisexual, and
                    queer women finding one another. We create uniquely
                    enjoyable
                  </span>
                  &nbsp;
                  <strong>
                    experiences designed for dating at the speed of lesbians.
                  </strong>
                </p>
                <br />
                <ul>
                  <li>
                    <span className="text-muted">10 DATES</span>
                  </li>
                  <li>
                    <span className="text-muted">TWO HOURS</span>
                  </li>
                  <li>
                    <span className="text-muted">RISK-FREE</span>
                  </li>
                  <li>
                    <span className="text-muted">REJECTION FREE</span>
                  </li>
                  <li>
                    <span className="text-muted">
                      LESBIAN DATING, ONLY FASTER THAN USUAL!
                    </span>
                  </li>
                </ul>
                <br />
                <h6>
                  <span style={{ fontWeight: "800" }}>
                    GET THE FREE DATING APP
                  </span>
                </h6>
                <br />
                <p>
                  <span className="text-muted">Imagine downloading a </span>
                  <strong>lesbian dating app</strong>{" "}
                  <span className="text-muted">
                    (LaidEvents Lesbian Dating App). Only, instead of swiping,
                    scrolling, and messaging, you have a list of LIVE events
                    that are available either in person (Covid-willing) or
                    online.
                  </span>
                </p>
                <p className="text-justify">
                  <span className="text-muted">
                    Then, on the day of the event you’ve registered to attend,
                    you will be joined by 25-50 other women-loving-women, along
                    with me, your event host. The event will start with all
                    participants in the Social Lobby. At live events, this is
                    the gathering area where you can mix and mingle with all
                    event attendees (and depending on the venue, you can
                    probably also grab an adult beverage of your choice). For
                    online events, The Social Lobby is the main gathering room
                    for virtual events where you will begin the online speed
                    dating experience.
                  </span>
                </p>
                <p className="text-justify">
                  <span className="text-muted">
                    Once you’re logged into your app (Lesbian Adventures in
                    Dating App), you will see “My Dates.” This will reveal your
                    list of 6-minute blind dates for the event and the table
                    number.
                  </span>
                </p>
                <p className="text-justify">
                  <span className="text-muted">
                    You will have up to 10 dates. Your dates are automatically
                    assigned based on your answers to questions about who you
                    are and what you like.
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-justify mt-md-5">
                  <span className="text-muted">
                    For 6 minutes, you will spend time getting to know each date
                    either at a specified table at live events or in a private
                    zoom room online. Six minutes goes fast (even in the most
                    awkward of situations). After each date, you will provide
                    your follow-up preferences in the app. (No Interest ❌,
                    Friendship Interest 👍🏼, or Romantic Interest ❤️)
                  </span>
                </p>
                <p className="text-justify">
                  <span className="text-muted">
                    Your date will timeout in 6 minutes. The lesbian dating app
                    will guide you from one date to the next, and you will also
                    have a couple of breaks that provide you with a chance to
                    socialize with other lesbian dating participants throughout
                    the event. At the end of the event, you will return to the
                    Social Lobby for a formal goodbye and a reminder of how to
                    view your matches from the event.
                  </span>
                </p>
                <p className="text-justify">
                  <span className="text-muted">
                    You will be provided with contact information for all
                    matches. This rejection-proof dating will communicate your
                    interests at the lowest common rating only. So, if you
                    indicate interest in romance, and she indicates interest in
                    friendship, you will be matched as friends. (She will not be
                    informed that you expressed interest in romance unless she
                    also expressed interest in romance). Nothing is communicated
                    if you indicate interest in friendship and she indicates no
                    interest. You will be matched for romance if you both
                    indicate interest in romance.
                  </span>
                </p>
                <br />
                <h6>
                  <span style={{ fontWeight: "800" }}>
                    OPPORTUNITY TO DEVELOP NEW FRIENDSHIPS, TOO
                  </span>
                </h6>
                <br />
                <p className="text-justify">
                  <span className="text-muted">
                    Social Groups allow you to meet women you may not be paired
                    with for a 1:1 date. You may also be in a social room with
                    someone you were on a date with. That makes random so fun –
                    you never know who will be there!
                  </span>
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 text-center">
                <h4>
                  <span style={{ fontWeight: "800" }}>
                    Sign Up to View and Register for Events
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="component_wrapper">{renderSteps()}</div>
    </div>
  );
};

export default Auth;
