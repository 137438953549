import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { eventListRequest, eventRegisterRequest } from "../../api/event";
import { getToken, removeToken, userInfo } from "../../cookiesHelper";
import { QRCode } from "react-qrcode-logo";
import Logo from "../../../src/LAID_logo_512.png";
import Android from "./android.png";
import Ios from "./ios.png";
import { loadScript } from "@paypal/paypal-js";
import "./home.css";
const Home = () => {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [loading, setLoading] = useState(false);
  const UserName = userInfo().firstName;
  const [id, setId] = useState("");
  const onLogout = () => {
    removeToken();
    navigate("/");
  };

  useEffect(() => {
    if (!getToken()) {
      onLogout();
    } else {
      //If user is authenticated get Event list
      eventList();
    }
  }, []);

  const eventList = () => {
    eventListRequest()
      .then((res) => {
        const events = filterEvent(res.data);
        setEvents(events);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const formatDate = (date) => {
    return moment(date).local().format("MM-DD-YYYY");
  };

  const formatTime = (time) => {
    return moment(time).local().format("hh:mm A");
  };

  const isFuture = (date) => {
    return moment(date).local().isAfter(moment());
  };

  const isRegistered = (event) =>
    event.attendees.filter((attendee) => attendee._id === userInfo().id)
      .length > 0;

  const filterEvent = (data) => {
    //Filter Future Events
    const futureEvents = data.sort(
      (event) => moment(event.startTime) - moment(event.endTime)
    );

    //Format date to Locale MM-DD-YYYY
    const event = futureEvents.map((event) => {
      return {
        ...event,
        date: formatDate(event.date),
        startTime: formatTime(event.startTime),
        endTime: formatTime(event.endTime),
        isRegistered: isRegistered(event),
        isFuture: isFuture(event.endTime),
      };
    });

    return event;
  };

  const onEventClicked = (event) => {
    const userId = userInfo()._id;

    setId(event._id);
    // console.log("userInfo", userInfo());
    const index = event.attendees.findIndex(
      (attendee) => attendee._id === userId
    );
    if (event.isRegistered && index > -1) {
      setBarcodeValue(event.token[index]);
      setModalShow(true);
      return;
    }
    if (event.price > 0) {
      return payViaPaypal(event);
    }
    registerEvent(event, "");
  };

  const registerEvent = (event, nonce) => {
    const request = {
      event: event._id,
      nonce,
    };
    console.log("event", event);
    setLoading(true);
    eventRegisterRequest(request)
      .then(() => {
        eventList();
        // setLoading(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const makeOrder = (actions, event) =>
    actions.order.create({
      purchase_units: [
        {
          description: `Event: ${event.title}`,
          amount: {
            currency_code: "USD",
            value: event.price,
          },
        },
      ],
    });

  const payViaPaypal = async (event) => {
    await loadScript({
      "client-id":
        window.env.CLIENT_ID ||
        "AeEK8cqNpSv8V178s27tN9VYnsT9lfD5YO2gDGzXORbwo4-rWxGzSDyhkI2tljIuidlHEqzycbQYSNjN",
      currency: "USD",
      // "client-id":
      //     window.env.CLIENT_ID ||
      //     "AfQzQ-_ZQX5_Q-_ZQX5_Q-_ZQX5_Q-_ZQX5_Q-_ZQX5_Q-_ZQX5_Q-_ZQX5_Q-_",
      // currency: "USD",
    })
      .then((paypal) => {
        paypal
          .Buttons({
            createOrder: function (data, actions) {
              return makeOrder(actions, event);
            },
            onApprove: async function (data, actions) {
              const details = await actions.order.capture();
              registerEvent(event, details.id);
              return details;
            },
            onError: function (err) {
              console.log(err);
            },
          })
          .render("#homeContainer")
          .catch((error) => {
            console.error("failed to render the PayPal Buttons", error);
          });
      })
      .catch((error) => {
        console.error("failed to load the PayPal JS SDK script", error);
      });
  };

  const handleModelClose = () => {
    setBarcodeValue("");
    setModalShow(false);
  };

  const handleClickev = (event) => {
    if (onEventClicked) {
      event.currentTarget.disabled = true;
      console.log("button clicked");
    }
    event.currentTarget.disabled = false;
  };

  return (
    <div style={{ flex: 1, height: "100%", width: "100%" }}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-right">
            <div
              className="login_text"
              style={{
                display: "inline-block",
                marginTop: "20px",
                float: "left",
                backgroundColor: "#595858",
                paddingLeft: "10px",
                paddingRight: "10px",
                color: "#fff",
                borderRadius: "25px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              User Name : {UserName}
            </div>
            <button
              className="mybtn my-3 mr-2"
              style={{ float: "right" }}
              onClick={onLogout}
            >
              <span className="login_text">LOGOUT</span>
            </button>
          </div>
        </div>
      </div>
      <br />

      <div className="main-div" id="homeContainer">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <h4 style={{ color: "#db6266" }}>Events</h4>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="row my-3">
              {events.map((event) => {
                return (
                  <div className="col-12 col-lg-4 my-3" key={event._id}>
                    <div class="card ">
                      <div class="card-header py-0 ">
                        {event.isOnline ? "Online" : "In-person"}
                      </div>
                      <div class="card-body">
                        <div className="row">
                          <div className="col-12">
                            <h6 class="card-title text-left text-uppercase">
                              <strong>{event.title}</strong>
                            </h6>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-5">
                            <p class="card-text text-left">
                              <strong>{event.date}</strong>
                            </p>
                          </div>
                          <div className="col-7 text-right">
                            <strong
                              style={{
                                color: "#fb9568",
                                fontSize: "13px",
                                lineHeight: "35px",
                              }}
                            >
                              {event.startTime}
                            </strong>
                            &nbsp; To &nbsp;
                            <strong
                              style={{
                                color: "#fb9568",
                                fontSize: "13px",
                                lineHeight: "35px",
                              }}
                            >
                              {event.endTime}
                            </strong>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-5 text-left">
                            <h5 class="card-title">{`$ ${event.price} `}</h5>
                          </div>
                          <div className="col-7 text-right my-auto">
                            <button
                              className="mybtnP py-1"
                              disabled={!event.isFuture}
                              color={event.isFuture ? "" : "#D3D3D3"}
                              onClick={() => onEventClicked(event)}
                            >
                              {!event.isFuture
                                ? "Expired"
                                : event.isRegistered
                                ? "View"
                                : "Register"}
                              {loading && id == event._id && (
                                <div
                                  className={
                                    !event.isFuture
                                      ? ""
                                      : event.isRegistered
                                      ? ""
                                      : "spinner-border mb-0"
                                  }
                                  role="status"
                                  style={{
                                    color: "#fb9568",
                                  }}
                                >
                                  <span
                                    className={
                                      !event.isFuture
                                        ? ""
                                        : event.isRegistered
                                        ? ""
                                        : "sr-only"
                                    }
                                  >
                                    {!event.isFuture
                                      ? ""
                                      : event.isRegistered
                                      ? ""
                                      : ""}
                                  </span>
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow && barcodeValue.length > 0}
        onHide={handleModelClose}
        barcode={barcodeValue}
        event={event}
      />
    </div>
  );
};

function MyVerticallyCenteredModal(props) {
  const { barcode } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "20px" }}
          className="mx-auto"
        >
          You are registered for the event successfully!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h5
            className="border-bottom pb-1"
            style={{ display: "inline-block", fontWeight: "500" }}
          >
            Download the app to view the dates
          </h5>
          <br />
          <span style={{ display: "inline-block" }}>
            <a href="#" className="nav-link px-4">
              <img src={Android} alt={Android} />
            </a>
          </span>
          <span style={{ display: "inline-block" }}>
            <a href="#" className="nav-link px-5">
              <img src={Ios} alt={Ios} />
            </a>
          </span>
        </div>
        {/* <div
                    style={{
                        flex: 1,
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    <QRCode
                        value={barcode}
                        logoWidth={50}
                        logoHeight={50}
                        eyeRadius={5}
                    />
                    <div>Token: {barcode}</div>
                </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="closebtnmodal">
          CLOSE
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Home;
