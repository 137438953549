import React, { useState, forwardRef, useImperativeHandle } from "react";
import Form from "react-bootstrap/Form";
import { changePasswordRequest } from "../../../../api/auth";
import { removeToken } from "../../../../cookiesHelper";
import './changePassword.css';

const ChangePasswordStep = forwardRef(
  ({ setErrors, success, errors, loading, successMessage }, ref) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const onPasswordChange = (e) => {
      setPassword(e.target.value);
    };

    const onConfirmPasswordChange = (e) => {
      setConfirmPassword(e.target.value);
    };

    useImperativeHandle(ref, () => ({
      submit() {
        if (!isValid()) return null;
        loading(true);
        changePasswordRequest({ password })
          .then((res) => {
            removeToken();
            loading(false);
            successMessage(res.message);
            success();
          })
          .catch((err) => {
            loading(false);
            errors["msg"] = err;
            setErrors(errors);
          });
      },
    }));

    const isValid = () => {
      const errors = {};
      if (password.trim() === "") errors["password"] = "Password is required";
      else if (password.trim().length < 6)
        errors["password"] = "Password must be at least 6 characters long";
      else if (password.trim() === "")
        errors["password"] = "Password is required";
      else if (confirmPassword.trim() === "")
        errors["confirmPassword"] = "Confirm Password is required";
      else if (confirmPassword.trim() !== password.trim())
        errors["confirmPassword"] =
          "Password is not equals to confirm password";
      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    return (
      <>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <div id="pass_div">
              {" "}
              <Form.Label>
                <element>Password</element>
              </Form.Label>
            </div>
            <Form.Control
              isInvalid={errors.password !== undefined}
              onChange={onPasswordChange}
              type="password"
              placeholder="Password"
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <div id="pass_div">
              {" "}
              <Form.Label>
                <element>Confirm Password</element>
              </Form.Label>
            </div>
            <Form.Control
              isInvalid={errors.confirmPassword !== undefined}
              onChange={onConfirmPasswordChange}
              type="password"
              placeholder="Confirm Password"
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </>
    );
  }
);

export default ChangePasswordStep;
