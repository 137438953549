import React, { useState, forwardRef, useImperativeHandle } from "react";
import Form from "react-bootstrap/Form";
import { forgotPasswordRequest } from "../../../../api/auth";
import { setToken } from "../../../../cookiesHelper";
import "./EmailStep.css";

const EmailStep = forwardRef(
  ({ setErrors, errors, success, loading, successMessage, email_set }, ref) => {
    const [email, setEmail] = useState("");

    const onEmailChange = (e) => {
      setEmail(e.target.value);
    };

    useImperativeHandle(ref, () => ({
      submit() {
        if (!isValid()) return null;
        loading(true);
        forgotPasswordRequest({ email })
          .then((res) => {
            loading(false);
            if (res.token) {
              setToken(res.token);
              successMessage(res.message);
              success(res.data.otp);
            } else {
              errors["msg"] = "Something went wrong with the token";
            }
          })
          .catch((err) => {
            loading(false);
            errors["msg"] = err;
            setErrors(errors);
          });
      },
    }));

    const isValid = () => {
      const errors = {};
      if (email.trim() === "") errors["email"] = "Email is required";
      else if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i))
        errors["email"] = "Email is invalid";
      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    return (
      <div className={email_set}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          {/* <Form.Label>Email address</Form.Label> */}
          <Form.Control
            className="forgetheight"
            isInvalid={errors.email !== undefined}
            onChange={onEmailChange}
            type="email"
            placeholder="ENTER EMAIL"
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
      </div>
    );
  }
);

export default EmailStep;
