import { getRequest, postRequest } from ".";
import moment from "moment-timezone";

const pref = "event";

export const eventListRequest = async () =>
  new Promise((resolve, reject) => {
    getRequest(`/${pref}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const eventRegisterRequest = async (data) =>
  new Promise((resolve, reject) => {
    postRequest(`/${pref}/register`, {
      ...data,
      timezone: moment.tz.guess(),
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
