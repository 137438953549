/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import Form from "react-bootstrap/Form";
import { agePrefRequest, iAmInsRequest } from "../../../../api/auth";
import Spinner from "react-bootstrap/Spinner";
import "./single.css";

const AreYouSingleStep = forwardRef(
    ({ setErrors, success, userInfo, setUserInfo, container_div }, ref) => {
        const [iAmInList, setIAmInList] = useState([]);
        const [agePrefList, setAgePrefList] = useState([]);
        const [isSingleChecked, setIsSingleChecked] = useState(false);
        const [loading, setLoading] = useState(true);

        const onChangeIAmInValue = (e) => {
            const { name, checked } = e.target;
            const updatedList = iAmInList.map((item) =>
                item._id === name ? { ...item, checked } : item
            );
            setIAmInList(updatedList);
        };

        const onChangeAgePrefValue = (e) => {
            const { name, checked } = e.target;
            const updatedList = agePrefList.map((item) =>
                item._id === name ? { ...item, checked } : item
            );
            setAgePrefList(updatedList);
        };

        useEffect(() => {
            iAmInsRequest()
                .then((res) => {
                    const list = res.data.map((item) => ({
                        ...item,
                        checked: false,
                    }));
                    let checkedList = list;
                    if (userInfo.iamins) {
                        checkedList = list.map((item) => ({
                            ...item,
                            checked: getCheckedUserData(
                                item._id,
                                userInfo.iamins
                            ),
                        }));
                    }
                    setIAmInList(checkedList);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log("err", err);
                });

            agePrefRequest()
                .then((res) => {
                    const list = res.data.map((item) => ({
                        ...item,
                        checked: false,
                    }));
                    let checkedList = list;
                    if (userInfo.ageprefs) {
                        checkedList = list.map((item) => ({
                            ...item,
                            checked: getCheckedUserData(
                                item._id,
                                userInfo.ageprefs
                            ),
                        }));
                    }
                    setAgePrefList(checkedList);
                })
                .catch((err) => {
                    console.log("err", err);
                });
        }, []);

        const getCheckedUserData = (id, list) => {
            const checkedUserData = list.find((itemId) => itemId === id);
            return checkedUserData ? true : false;
        };

        useImperativeHandle(ref, () => ({
            submit() {
                if (!isValid()) return null;
                const iAmInCheckedList = iAmInList.filter(
                    (item) => item.checked
                );

                //Only save I am In id's
                userInfo["iamins"] = iAmInCheckedList.map((item) => item._id);

                const agePrefCheckedList = agePrefList.filter(
                    (item) => item.checked
                );

                //Only save Age Pref's id's
                userInfo["ageprefs"] = agePrefCheckedList.map(
                    (item) => item._id
                );

                console.log("userInfo", userInfo);

                setUserInfo(userInfo);
                success();
            },
        }));

        const isValid = () => {
            const errors = {};

            const iAmInCheckedList = iAmInList.filter((item) => item.checked);
            const agePrefCheckedList = agePrefList.filter(
                (item) => item.checked
            );

            if (iAmInCheckedList.length === 0) {
                errors["msg"] =
                    "Please select I am in my ...* at least 1 checkbox";
            } else if (agePrefCheckedList.length === 0) {
                errors["msg"] =
                    "Please select Age Preferences at least 1 checkbox";
            } else if (!isSingleChecked) {
                errors["msg"] = "Please confirm that you are single";
            }

            setErrors(errors);
            return Object.keys(errors).length === 0;
        };

        return (
            <div className={container_div}>
                {loading ? (
                    <div className="spinner_div">
                        <div
                            class="spinner-border mb-0"
                            role="status"
                            style={{ color: "#fb9568" }}
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <div>
                            <h6 className="mb-2">I AM IN MY ...</h6>
                            {iAmInList.map((item) => (
                                <label class="container">
                                    {" "}
                                    {item.name}
                                    <input
                                        type="checkbox"
                                        id={item._id}
                                        name={item._id}
                                        onChange={onChangeIAmInValue}
                                        checked={item.checked}
                                        label={item.name}
                                        className="lebelcheck"
                                    />
                                    <span class="checkmark"></span>
                                </label>
                            ))}
                        </div>
                        <br />
                        <div>
                            <h6 className="mb-2">DATING AGE PREFERENCES</h6>
                            <small className="text-muted text-top-15">
                                (check all that apply)
                            </small>
                            {agePrefList.map((item) => (
                                <label class="container">
                                    {" "}
                                    {item.name}
                                    <input
                                        type="checkbox"
                                        id={item._id}
                                        name={item._id}
                                        onChange={onChangeAgePrefValue}
                                        checked={item.checked}
                                        label={item.name}
                                        className="lebelcheck"
                                    />
                                    <span class="checkmark"></span>
                                </label>
                            ))}
                        </div>
                        <br />
                        <div>
                            <h6 className="mb-2">ARE YOU SINGLE ?</h6>
                            <label class="container">
                                YES, I am single / available.*
                                <input
                                    type="checkbox"
                                    id={"singleId"}
                                    onChange={(e) =>
                                        setIsSingleChecked(e.target.checked)
                                    }
                                    checked={isSingleChecked}
                                    label={"YES, I am single / available.*"}
                                    className="lebelcheck"
                                />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </>
                )}
            </div>
        );
    }
);

export default AreYouSingleStep;
