import React, { useState, useEffect } from "react";
import Logo from "../../LAID_logo_512.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./navbar.css"; // Custom CSS file for any additional styles

const HamburgerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 12H21M3 6H21H3ZM3 18H21H3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const Navbar = () => {
  const [navbarShadow, setNavbarShadow] = useState(false);
  const [toggle, setToggle] = useState(false); // Controls whether the menu is open
  const [active, setActive] = useState(""); // For active nav item
  const [openDropdown, setOpenDropdown] = useState(null); // For managing dropdowns

  const navLinks = [
    {
      id: 1,
      title: "Home",
      url: "/",
      dropdownItems: null,
    },
    {
      id: 2,
      title: "Privacy Policy",
      url: "/privacy-policy",
      dropdownItems: null,
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setNavbarShadow(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id); // Toggle dropdown
  };

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light bg-light sticky-top ${
        navbarShadow ? "navbar-shadow" : ""
      }`}
    >
      <div className="container-fluid">
        {/* Navbar Brand (Logo) */}
        <a className="navbar-brand" href="/">
          <img
            src={Logo}
            alt="Logo"
            width="55"
            height="40"
            className="d-inline-block align-top"
          />
        </a>

        {/* Toggler for Mobile */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setToggle(!toggle)} // Toggle the menu
        >
          {/* Conditionally render Hamburger or Close icon based on toggle state */}
          {toggle ? <CloseIcon /> : <HamburgerIcon />}
        </button>

        {/* Collapsible Navbar */}
        <div className={`collapse navbar-collapse ${toggle ? "show" : ""}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {navLinks.map((nav) => (
              <li
                key={nav.id}
                className={`nav-item ${
                  active === nav.title ? "active" : ""
                }`}
              >
                {nav.dropdownItems ? (
                  <div onClick={() => handleOpenDropdown(nav.id)}>
                    <div className="d-flex align-items-center">
                      <p className="nav-link mb-0">{nav.title}</p>
                    </div>
                    {openDropdown === nav.id && (
                      <div className="dropdown-menu show mt-2">
                        {nav.dropdownItems.map((item) => (
                          <a
                            key={item.id}
                            href={item.url}
                            className="dropdown-item"
                          >
                            {item.linkText}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <a
                    className="nav-link"
                    href={nav.url}
                    onClick={() => {
                      setActive(nav.title);
                      setToggle(false); // Close the menu after navigation
                    }}
                  >
                    {nav.title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Mobile Sidebar */}
        {toggle && (
          <div
            className="position-fixed text-white w-100 z-10"
            style={{
              top: "60px",
              left: "0",
              height: "calc(100vh - 60px)",
              backgroundColor: "#DC6266",
            }}
          >
            <ul className="list-unstyled text-center mt-4">
              {navLinks.map((nav) => (
                <li key={nav.id} className="mb-3">
                  {nav.dropdownItems ? (
                    <div>
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="mr-2">{nav.title}</p>
                      </div>
                      {openDropdown === nav.id && (
                        <div className="bg-light text-dark p-2">
                          {nav.dropdownItems.map((item) => (
                            <a
                              key={item.id}
                              className="d-block py-2 text-dark"
                              href={item.url}
                            >
                              {item.linkText}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <a
                      href={nav.url}
                      className="text-white"
                      onClick={() => {
                        setActive(nav.title);
                        setToggle(false);
                      }}
                    >
                      {nav.title}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
