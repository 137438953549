import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import {
    LOGIN_STEP,
    // REGISTER_APPLY_TO_YOU_STEP,
    // REGISTER_ARE_YOU_SINGLE_STEP,
    REGISTER_BASIC_DETAILS_STEP,
    REGISTER_CONFIRM_OTP_STEP, 
    // REGISTER_WISH_TO_ATTRACT_STEP,
} from "../../../constants";
import BasicInfo from "./basicInfoStep";
import ApplyToYouStep from "./applyToYouStep";
import WishToAttractStep from "./wishToAttractStep";
import AreYouSingleStep from "./areYouSingleStep";
import { confirmRegisterRequest, registerRequest } from "../../../api/auth";
import { setToken } from "../../../cookiesHelper";
import OTPStep from "../forgotPassword/OTPStep";
import "./register.css";
import axios from "axios";

const Register = ({ changeStep }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [errors, setErrors] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [steps, setSteps] = useState(REGISTER_BASIC_DETAILS_STEP);
    const [errorflag, setErrorFlag] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");
    const [otp, setOtp] = useState("");
    const [ doubleRegistrationError , setDoubleRegistrationError] = useState(false);
    const ref = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        ref.current.submit();
    };
    
    // const isValid = () => {
    //     const errors = {};
    //     if (!userInfo.firstName) {
    //       errors["msg"] = "First Name is required";
    //       setErrorFlag(true);
    //     } else if (userInfo.firstName && userInfo.firstName.trim().length < 3) {
    //       errors["firstName"] = "First Name is too short";
    //       setErrorFlag(true);
    //     } else if (!userInfo.firstName.match(/^[^0-9][^@#]+$/)) {
    //       errors["firstName"] = "Name takes only letters";
    //       setErrorFlag(true);
    //     } else if (!userInfo.lastName) {
    //       errors["lastName"] = "Last Name is required";
    //       setErrorFlag(true);
    //     }

    //     if (userInfo.firstName && userInfo.lastName.trim().length < 3) {
    //       errors["lastName"] = "Last Name is too short";
    //       setErrorFlag(true);
    //     } else if (!userInfo.lastName.match(/^[^0-9][^@#]+$/)) {
    //       errors["lastName"] = "Last Name is invalid";
    //       setErrorFlag(true);
    //     } else if (!userInfo.nickName) {
    //       errors["nickName"] = "Nick Name is required";
    //       setErrorFlag(true);
    //     }

    //     if (userInfo.nickName.trim().length < 3) {
    //       errors["nickName"] = "Nick Name is too short";
    //     } else if (!userInfo.nickName.match(/^[^0-9][^@#]+$/)) {
    //       errors["nickName"] = "Nick Name is invalid";
    //       setErrorFlag(true);
    //     }

    //     if (!userInfo.dob) {
    //       errors["dob"] = "Date of Birth is required";
    //     } else if (userInfo.dob > 21) {
    //       errors["dob"] = "Age must be 21";
    //       setErrorFlag(true);
    //     }

    //     if (!userInfo.email) {
    //       errors["email"] = "Email is required";
    //       setErrorFlag(true);
    //     } else if (userInfo.email.length === 0) {
    //       errors["email"] = "Please enter email";
    //       setErrorFlag(true);
    //     } else if (!userInfo.email.match(/^[^0-9][^@]+@[^@]+\.[^@]+$/)) {
    //       errors["email"] = "Email is invalid";
    //       setErrorFlag(true);
    //     } else {
    //         //
    //     }

    //     if (!userInfo.password) {
    //       errors["password"] = "Password is required";
    //       setErrorFlag(true);
    //     } else if (userInfo.password.trim().length < 6) {
    //       errors["password"] = "Password is too short";
    //       setErrorFlag(true);
    //     } 

    //     if (!userInfo.mobile) {
    //       errors["mobile"] = "Mobile is required";
    //       setErrorFlag(true);
    //     } else if (userInfo.mobile.length < 10 && userInfo.mobile.length > 10) {
    //       errors["mobile"] = "Mobile should have 10 digits";
    //       setErrorFlag(true);
    //     } else if (userInfo.mobile.length === 0) {
    //       errors["mobile"] = "Please enter mobile number";
    //       setErrorFlag(true);
    //     } 

    //     if (!userInfo.mobile.match("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$")) {
    //       errors["mobile"] = "Mobile isgd invalid";
    //       setErrorFlag(true);
    //     }         
    //     setErrors(errors);        
    //     return Object.keys(errors).length === 0;
    //   };

    // const nextStep = (e) => {
    //     if (e) e.preventDefault();
    //     if (steps === REGISTER_BASIC_DETAILS_STEP) {  
    //         checkEmailandmobile(); 
    //     } 
    //     //If it is the last step, then go to login
    //     if (steps === REGISTER_CONFIRM_OTP_STEP) {
    //         goToLogin();
    //     } else {
    //         setSteps(steps + 1);
    //     }
    // };

    const nextStep = (e) => {
        if (e) e.preventDefault();
        if (steps === REGISTER_BASIC_DETAILS_STEP) {  
            checkEmailandmobile(); 
        } 
        if (steps === REGISTER_CONFIRM_OTP_STEP) {
            goToLogin();
        } else {
            setSteps(steps + 1);
        }
    };

    const checkEmailandmobile = () => { 
        axios
            .post(
                `https://server.lesbianloveadv.dating/v1/auth/checkEmailPhoneExist`,
                {
                    mobile: userInfo.mobile,
                    email: userInfo.email,
                }
            )
            .then((response) => {
                if (response.data.message === "email exist!") {
                    return (
                        // showErrorModal("email exist!"),
                        setErrorMessage("email exist!"),
                        setSteps(steps + 0),
                        window.alert("email exist!")
                    );
                } else if (
                    response.data.message === "email and mobile not register!"
                ) {
                    setErrorMessage("");
                } else if (response.data.message === "mobile no. exist!") {
                    return (
                        setErrorMessage("mobile number exist!"),
                        window.alert("mobile number exist!"),
                        setSteps(steps + 0)
                    );
                } else if (
                    response.data.message === "email and mobile not register!"
                ) {
                    setErrorMessage("");
                }
            })
            .catch((err) => console.log(err.response.data));
    };

    // const onBack = (e) => {
    //     e.preventDefault();
    //     //If it is the first step, then go to login
    //     if (steps === REGISTER_BASIC_DETAILS_STEP) {     
    //         goToLogin();
    //     } else {
    //         setSteps(steps - 1);
    //     }
    // };



    const goToLogin = () => {
        changeStep(LOGIN_STEP);
    };

    const goToregistration = () => {
        setSteps(steps);
    }

    // const renderSteps = () => {
    //     switch (steps) {
    //         case REGISTER_BASIC_DETAILS_STEP:
    //             return (
    //                 <BasicInfo
    //                     ref={ref}
    //                     userInfo={userInfo}
    //                     setUserInfo={setUserInfo}
    //                     errors={errors || errorMessage}
    //                     setErrors={handleError}
    //                     success={nextStep}
    //                     basic_info={"basic_info"}
    //                 />
    //             );
    //         case REGISTER_APPLY_TO_YOU_STEP:
    //             return (
    //                 <ApplyToYouStep
    //                     ref={ref}
    //                     userInfo={userInfo}
    //                     setUserInfo={setUserInfo}
    //                     errors={errors}
    //                     setErrors={handleError}
    //                     success={nextStep}
    //                     background={"background"}
    //                 />
    //             );
    //         case REGISTER_WISH_TO_ATTRACT_STEP:
    //             return (
    //                 <WishToAttractStep
    //                     ref={ref}
    //                     userInfo={userInfo}
    //                     setUserInfo={setUserInfo}
    //                     errors={errors}
    //                     setErrors={handleError}
    //                     success={nextStep}
    //                     attract={"attract"}
    //                 />
    //             );
    //         case REGISTER_ARE_YOU_SINGLE_STEP:
    //             return (
    //                 <AreYouSingleStep
    //                     ref={ref}
    //                     userInfo={userInfo}
    //                     setUserInfo={setUserInfo}
    //                     errors={errors}
    //                     setErrors={handleError}
    //                     success={registerUser}
    //                     container_div={"container_div"}
    //                 />
    //             );
    //         case REGISTER_CONFIRM_OTP_STEP:
    //             return (
    //                 <OTPStep
    //                     otp={otp}
    //                     ref={ref}
    //                     errors={errors}
    //                     setErrors={handleError}
    //                     success={confirmRegister}
    //                 />
    //             );
    //         default:
    //             return <div>
    //                 hello
    //             </div>;
    //             // return navigate("/");
    //     }
    // };

    const renderSteps = () => {
        switch (steps) {
            case REGISTER_BASIC_DETAILS_STEP:
                return (
                    <BasicInfo
                        ref={ref}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        errors={errors || errorMessage}
                        setErrors={handleError}
                        success={nextStep}
                        basic_info={"basic_info"}
                    />
                );
            case REGISTER_ARE_YOU_SINGLE_STEP:
                return (
                    <AreYouSingleStep
                        ref={ref}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        errors={errors}
                        setErrors={handleError}
                        success={registerUser}
                        container_div={"container_div"}
                    />
                );
            case REGISTER_CONFIRM_OTP_STEP:
                return (
                    <OTPStep
                        otp={otp}
                        ref={ref}
                        errors={errors}
                        setErrors={handleError}
                        success={confirmRegister}
                    />
                );
            default:
                return <div>Hello</div>;
        }
    };
    

    const handleError = (err) => {
        console.log(steps);
        console.log('error');
        setErrors(err);
        if (Object.keys(err).length === 0 || !err.msg) {
            return;
        }
        console.log('found error');
        setModalShow(true);
    };

    const handleModalClose = () => {
        setModalShow(false);
        if (successMessage.length !== 0) {
            console.log('inside');
            setSuccessMessage("");
            nextStep();
        }

        // Handle error in case user goes backward in otp page/component
        // first identify when user goes backward in otp page/component
        // if (doubleRegistrationError) {
        //     setDoubleRegistrationError(false);
        //     console.log('trying to navigate ')
        //     // goToLogin();
        //     navigate('/activateAccount')
        // }

        detectDoubleRegistration()
        // Set state to that
        // 
        // goToLogin();
    };

    const detectDoubleRegistration = () => {
        if (doubleRegistrationError) {
            setDoubleRegistrationError(false);
            console.log('trying to navigate ')
            // goToLogin();
            navigate('/activateAccount')
        }
    }

    const registerUser = async () => {
        setLoading(true);
        registerRequest(userInfo)
            .then((res) => {
                setLoading(false);
                setToken(res.token);
                setOtp(res.data.otp);
                showSuccessModal(`OTP sent to ${userInfo.email}`);
            })
            .catch((err) => {
                setLoading(false);
                console.log(loading);
                errors["msg"] = err;
                setDoubleRegistrationError(true)
                handleError(errors);
            });
    };

    const confirmRegister = () => {
        setLoading(true);
        confirmRegisterRequest({ otp })
            .then(() => {
                setLoading(false);
                setToken();
                registerSuccess();
            })
            .catch((err) => {
                setLoading(false);
                errors["msg"] = err;
                handleError(errors);
            });
    };

    const showSuccessModal = (msg) => {
        setSuccessMessage(msg);
        setModalShow(true);
    };

    // const showErrorModal = (msg) => {
    //     setErrorMessage(msg);
    //     setModalShow(true);
    // };

    const registerSuccess = () => {
        showSuccessModal("Verification Completed");
    };

    return (
        <div onClick={detectDoubleRegistration} className="register_wrapper ">
            <Form>
                {errorMessage && (
                    <div className="error text-danger">
                        <p>{errorMessage} </p>
                    </div>
                )}
                {renderSteps()}
                <div style={{ marginTop: "20px" }} />
                <Row>
                    <Col className="left-text">
                        <button className="mybtn" onClick={onBack}>
                            BACK
                        </button>
                    </Col>
                    <Col className="left-right">
                        <button
                            className="mybtn"
                            onClick={handleSubmit}
                            style={
                                loading ? { backgroundColor: "#D3D3D3" } : {}
                            }
                        >
                            <span className="login_text">
                                {steps === REGISTER_CONFIRM_OTP_STEP
                                    ? "FINISH"
                                    : "NEXT"}
                                {loading && (
                                    <div
                                        class="spinner-border mb-0"
                                        role="status"
                                        style={{ color: "#fb9568" }}
                                    >
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                )}
                            </span>
                        </button>
                    </Col>
                </Row>
                <div className="btn_div"></div>
                <Modal show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header>
                        <Modal.Title>
                            {successMessage.length !== 0 ? "Success" : "Error"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successMessage.length !== 0
                            ? successMessage
                            : errors.msg}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            CLOSE
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        </div>
    );
};

export default Register;
