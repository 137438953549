import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

export const setToken = (token) => {
  const cookies = new Cookies();
  cookies.set("token", token);
};

export const getToken = () => {
  const cookies = new Cookies();
  return cookies.get("token");
};

export const removeToken = () => {
  const cookies = new Cookies();
  cookies.set("token", "");
};

export const setAdmin = (value) => {
  const cookies = new Cookies();
  cookies.set("admin", value);
};

export const getAdmin = () => {
  const cookies = new Cookies();
  return cookies.get("token");
};

export const userInfo = () => {
  return jwt_decode(getToken());
};
