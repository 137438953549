import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaXTwitter } from "react-icons/fa6";
import "./footer.css";
 
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">

        <div className="footer-content">
          
          <div className="footer-text">
            {/* Copyright Text */}
            <p><strong style={{
              fontSize: "18px"
            }}> © Lesbian Adventures in Love.</strong> All Rights Reserved</p>

            {/* Privacy Policy Link */}
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>

          <div className="social-media-container">
            {/* Social Media Icons */}
          <div className="social-media">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookF size={30} />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn size={30} />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram size={30} />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <FaXTwitter size={30} />
            </a>
          </div>
          </div>
          
        </div>

      </div>
    </footer>
  );
};

export default Footer;
