import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Route, Routes, useNavigate } from "react-router-dom";
import Auth from "./screens/auth";
import Home from "./screens/home";
import Admin from "./screens/admin";
import Detail from "./screens/detail"
import Done from "./screens/activateAccount";
import ActivateAccount from "./screens/activateAccount";
import PrivacyPolicy from "./screens/privacyPolicy";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<Auth />} />
        <Route path="/activateAccount" element ={<ActivateAccount />} />
        <Route path="/" element={<Auth />} />
        <Route path="/detail&login" element={<Detail />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/done" element={<ActivateAccount />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
