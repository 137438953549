import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { LOGIN_STEP } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { confirmRegisterRequest } from "../../../api/auth";
import OTPStep from "../forgotPassword/OTPStep";

const EmailVerification = ({ changeStep }) => {
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState("");
  let otp = "";

  const ref = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    ref.current.submit();
  };

  const goToLogin = () => {
    changeStep(LOGIN_STEP);
  };

  const handleError = (err) => {
    setErrors(err);
    setLoading(false);
    if (Object.keys(err).length === 0 || !err.msg) {
      return;
    }
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    if (successMessage.length !== 0) {
      setSuccessMessage("");
      goToLogin();
    }
  };

  const verifyUser = () => {
    setLoading(true);
    confirmRegisterRequest({ otp })
      .then(() => {
        setLoading(false);
        setSuccessMessage("Email verified successfully");
        setModalShow(true);
      })
      .catch((err) => {
        const errorObject = {};
        errorObject["msg"] = err ? err : "OTP not matched";
        handleError(errorObject);
      });
  };

  return (
    <>
      <Form>
        <OTPStep
          ref={ref}
          setOtp={(value) => (otp = value)}
          loading={setLoading}
          setErrors={setErrors}
          showError={setModalShow}
          errors={errors}
          success={verifyUser}
        />
        <div style={{ marginTop: "20px" }} />
        <div className="btn_div">
          <Button variant="primary" onClick={goToLogin}>
            BACK
          </Button>
          <button
            onClick={handleSubmit}
            style={loading ? { backgroundColor: "#D3D3D3" } : {}}
          >
            <span className="login_text">
              FINISH
              {loading && (
                <div
                  class="spinner-border mb-0"
                  role="status"
                  style={{ color: "#fb9568" }}
                >
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </span>
          </button>
          <Modal show={modalShow} onHide={() => setModalShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {successMessage.length !== 0 ? "Success" : "Error"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {successMessage.length !== 0 ? successMessage : errors.msg}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                CLOSE
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Form>
    </>
  );
};

export default EmailVerification;
