//Auth Steps
export const LOGIN_STEP = 0;
export const REGISTER_STEP = 1;
export const FORGOT_PASSWORD_STEP = 2;
export const VERIFY_EMAIL_STEP = 3;

//Forgot password steps
export const FORGOT_EMAIL_STEP = 0;
export const FORGOT_OTP_STEP = 1;
export const FORGOT_CHANGE_PASSWORD_STEP = 2;

//Register Steps
// export const REGISTER_BASIC_DETAILS_STEP = 0;
// export const REGISTER_APPLY_TO_YOU_STEP = 1;
// export const REGISTER_WISH_TO_ATTRACT_STEP = 2;
// export const REGISTER_ARE_YOU_SINGLE_STEP = 3;
// export const REGISTER_CONFIRM_OTP_STEP = 4;

export const REGISTER_BASIC_DETAILS_STEP = 0;
// export const REGISTER_ARE_YOU_SINGLE_STEP = 1;
export const REGISTER_CONFIRM_OTP_STEP = 1;
