import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./privacyPolicy.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      {/* Privacy Policy Text Section */}
      <div className="container mt-5">
        <h1>Privacy Policy</h1>

        <p>
          At Lesbian Adventures in Dating (the "App"), we are committed to
          protecting your privacy. This Privacy Policy explains how your
          personal information is collected, used, and disclosed when you use
          our app. By using the App, you agree to the collection and use of
          information in accordance with this Privacy Policy.
        </p>

        <h3>1. Information We Collect</h3>
        <p>
          We collect the following types of information:
          <br />
          <strong>• Personal Information:</strong> When you create an account,
          we collect personal information such as your name, date of birth,
          email address, and city, state, and zip code to match you with
          potential dates. This information is essential for providing a
          personalized experience.
          <br />
          <strong>• Usage Data:</strong> We collect information on how you
          interact with the app, such as the features you use, the time spent on
          the app, and your preferences. This helps us improve the app's
          performance and user experience.
          <br />
          <strong>• Cookies:</strong> The App may use cookies to collect data
          related to your preferences and browsing activity. You can disable
          cookies in your browser settings, but this may limit your use of
          certain features of the App.
        </p>

        <h3>2. How We Use Your Information</h3>
        <p>
          We use the information we collect for the following purposes:
          <ul>
            <li>To provide, operate, and maintain the app</li>
            <li>To personalize your experience based on your profile information</li>
            <li>To communicate with you about your account, updates, and promotions</li>
            <li>To improve our app and address any technical issues</li>
            <li>To ensure compliance with legal requirements and enforce our terms of service</li>
          </ul>
        </p>

        <h3>3. How We Share Your Information</h3>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your information in the following circumstances:
          <br />
          <strong>• Service Providers:</strong> We may share your information
          with third-party service providers who help us operate the app (e.g.,
          hosting, analytics). These providers have access to your personal
          data only to perform specific tasks on our behalf and are obligated
          not to disclose or use it for any other purpose.
          <br />
          <strong>• Legal Requirements:</strong> We may disclose your
          information if required to do so by law or in response to valid
          requests by public authorities.
        </p>

        <h3>4. Security of Your Information</h3>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee absolute security.
        </p>

        <h3>5. Your Rights</h3>
        <p>
          You have the right to access, update, or delete your personal
          information at any time. To exercise these rights, please contact us
          at michele@micheleomara.com.
        </p>

        <h3>6. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the "Effective Date" at the top will be
          updated accordingly. Your continued use of the app after any changes
          to this policy will signify your acceptance of the updated terms.
        </p>

        <h3>7. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
          <br />
          Michele O'Mara
          <br />
          Email: michele@micheleomara.com
          <br />
          Website: www.lesbianloveadv.com
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
