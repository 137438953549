import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  LOGIN_STEP,
  REGISTER_STEP,
  FORGOT_PASSWORD_STEP,
  VERIFY_EMAIL_STEP,
} from "../../constants";
import { getAdmin } from "../../cookiesHelper";
import Logo from "../../LAID_logo_512.png";
import EmailVerification from "./emailVerification";
import ForgotPassword from "./forgotPassword";
import Login from "./login";
import Register from "./register";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
// import "/"

const Auth = () => {
  const [authStep, setAuthStep] = useState(LOGIN_STEP);
  const navigate = useNavigate();

  useEffect(() => {
    if (getAdmin()) {
      navigate("/admin");
    } else {
      console.log()
      // setAuthStep(LOGIN_STEP);
    }
  });

  const renderSteps = () => {
    switch (authStep) {
      case LOGIN_STEP:
        return <Login login_css="login_css" changeStep={setAuthStep} />;
      case REGISTER_STEP:
        return <Register changeStep={setAuthStep} />;
      case FORGOT_PASSWORD_STEP:
        return <ForgotPassword changeStep={setAuthStep} />;
      case VERIFY_EMAIL_STEP:
        return <EmailVerification changeStep={setAuthStep} />;
      default:
        return <Login />;
    }
  };

  return (
    <div>
      
      <Navbar />
      <div className="login_wrapper">
        <div className="login_logo">
          <img src={Logo} alt="pic" />
        </div>
        <div className="component_wrapper">
          {renderSteps()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Auth;
